import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
    <Layout>
        <section className="section section--gradient">
            <div className="container">
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
